<template>
  <!-- 新闻列表页 -->
  <div class="news-list">
    <div class="news-items">
      <news-card
        v-for="(item, index) in newsList"
        :key="index"
        :newsData="item"
        @viewDetail="viewDetails(item)"
      />
    </div>

    <el-pagination
      background
      layout="prev, pager, next,total"
      :total="params.total"
      @current-change="currentChange"
    />
  </div>
</template>

<script>
import { getNewsList } from "@/api/news";

export default {
  name: "newsList",
  data() {
    return {
      newsList: [], // 新闻列表
      params: {
        page: 0,
        size: 10,
        total: 0,
        sort: "updatedAt,desc",
      }, // 请求参数
    };
  },
  created() {
    this.getNewsList();
  },
  methods: {
    async getNewsList() {
      let res = await getNewsList(this.params);
      this.newsList = res.data?._embedded?.news;
      this.params.total = res.data.page.totalElements;
    }, // 获取新闻列表
    currentChange(page) {
      this.params.page = page - 1;
      this.getNewsList(this.params);
    }, // 页码变化
    viewDetails(item) {
      // console.log({ item, routes: this.$route });
      let id = item._links.self.href.split("/").pop();
      this.$router.push(`/news/view/${id}`);
    }, // 查看详情
  },
};
</script>

<style lang="less" scoped>
// 新闻列表
.news-list {
  width: 780px;

  .news-items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .news-item {
      margin-bottom: 30px;
    }
  }
}
</style>
